import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[prvMascaraMesano]'
})
export class MascaraMesanoDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event) {
    
    if (this.el.nativeElement.value.length > 7){
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/.$/g, '');
      event.stopPropagation();
    }
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
    
    var newVal = this.el.nativeElement.value; 

    if (newVal.length <= 2) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1');
    }else{
      newVal = newVal.replace(/^(\d{0,2})(.*)/, '$1/$2')
    }
    this.el.nativeElement.value = newVal;
  }

}
