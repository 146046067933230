import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'prv-complementar-item',
  templateUrl: './complementar-item.component.html',
  styleUrls: ['./complementar-item.component.scss']
})
export class ComplementarItemComponent implements OnInit {

  @Input() visivel:boolean=false;

  constructor() { }

  ngOnInit() {
  }

}
