import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { Filtro } from 'src/app/global/tipos/tiposGenericos';
import { HttpClient } from '@angular/common/http';
import { ParametrosPesquisaComponent } from '../parametros-pesquisa/parametros-pesquisa.component';
import { RespostaServidorVO } from 'src/app/global/tiposPrevimil';
import { GerenciadorErroRequisicaoService } from '../../utilitarios/gerenciadorErroRequisicaoService';

@Component({
  selector: 'prv-quadro-dialogo-dinamico',
  templateUrl: './quadro-dialogo-dinamico.component.html',
  styleUrls: ['./quadro-dialogo-dinamico.component.scss']
})
export class QuadroDialogoDinamicoComponent implements OnInit {

  @Input() titulo: string;
  @Input() parametros: Filtro [] = [];
  @Input() endpoint: string;
  @Input() botaoTexto: string;
  @Input() exibeDialogo: boolean = false;

  @ViewChild('parametrosPesquisa', { static: false }) parametrosPesquisa: ParametrosPesquisaComponent;
  
  @Output() exibeDialogoChange = new EventEmitter<boolean>();
  @Output() concluido = new EventEmitter();
  
  carregando: boolean = false;
  parametrosAtivos: any = {};
  requisicaoValida: boolean = true;

  mensagemErro: string;
  mensagemSucesso: string;

  constructor(private renderer: Renderer2,
    private httpClient: HttpClient,
    private gerenciadorErroRequisicaoService: GerenciadorErroRequisicaoService) { }

  ngOnInit() {
  }

  pegarParametros(){

  }

  fecharDialogo(){
    this.exibeDialogoChange.emit(false);
  }

  montarParametros(){
    return this.parametrosPesquisa.montarParametros()
  }

  verificarRequisicaoValida(){
    let parametros = this.parametrosAtivos;
    if (Object.keys(parametros).length==0){
      this.requisicaoValida=false;
    }else{
      this.requisicaoValida=true;
    }
  }

  enviarParametros(){
    this.parametrosAtivos = this.montarParametros();
    this.verificarRequisicaoValida()
    if (!this.requisicaoValida){
      return;
    }
    this.carregando = true;
    this.httpClient.post(this.endpoint, this.parametrosAtivos).subscribe(resposta => {
      let respostaServidor = (resposta as RespostaServidorVO<any>);
      this.mensagemSucesso = respostaServidor.sucesso;
      this.carregando = false;
      this.concluido.emit();
      //Precisa ser refatorado!!
      if (respostaServidor.validacao.possuiImpedimento){
        this.mensagemErro = this.gerenciadorErroRequisicaoService.tratarMensagemErroRequisicaoParaTexto(
          {error: {criticas: respostaServidor.validacao.criticas}}, "Ocorreu um erro.")
      }
    },
    falha => {
      console.log(falha);
      this.mensagemErro = this.gerenciadorErroRequisicaoService.tratarMensagemErroRequisicaoParaTexto(falha, "Ocorreu um erro.")
    });
  }
}
