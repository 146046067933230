import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardUsuarioComponent } from './global/telas/dashboard-usuario/dashboard-usuario.component';
import { GuardaUsuarioAutenticadoService } from './frameworks/seguranca/guardas/guarda-usuario-autenticado.service';
import { TelaAutenticacaoComponent } from './frameworks/seguranca/telas/tela.autenticacao/tela.autenticacao.component';
import { GuardaUsuarioNaoAutenticadoService } from './frameworks/seguranca/guardas/guarda-usuario-nao-autenticado.service';
import { GuardaAcessoDashboardGuard } from './global/guardas/guarda-acesso-dashboard.guard';
import { JanelaUsuarioComponent } from './fichas/janelas/janela-usuario/janela-usuario.component';
import { GuardaAcessoModuloService } from 'src/app/frameworks/seguranca/guardas/guarda-acesso-modulo.service';
import { MensagemComponent } from './modulos/mensagem/mensagem.component';

//, canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]}

const routes: Routes = [
  {path: "", redirectTo: "principal" , pathMatch:'full'},  
  {path: "principal", component: DashboardUsuarioComponent, canActivate: [GuardaUsuarioAutenticadoService]},  
  {path: "telemarketing", loadChildren: () => import('./modulos/telemarketing/telemarketing.module').then(m => m.TelemarketingModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "comercial", loadChildren: () => import('./modulos/comercial/comercial.module').then(m => m.ComercialModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "diversos", loadChildren: () => import('./modulos/diversos/diversos.module').then(m => m.DiversosModule)},
  {path: "gestaoInterna", loadChildren: () => import('./modulos/gestao-interna/gestao-interna.module').then(m => m.GestaoInternaModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "financeiro", loadChildren: () => import('./modulos/financeiro/financeiro.module').then(m => m.FinanceiroModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "integracao", loadChildren: () => import('./integracoes/integracoes.module').then(m => m.IntegracoesModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "operacoes", loadChildren: () => import('./modulos/operacoes/operacoes.module').then(m => m.OperacoesModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "anuenciaEletronica", loadChildren: () => import('./modulos/anuencia-eletronica/anuencia-eletronica.module').then(m => m.AnuenciaEletronicaModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "controleAprovacao", loadChildren: () => import('./modulos/controle-aprovacao/controle-aprovacao.module').then(m => m.ControleAprovacaoModule), canActivate: [GuardaUsuarioAutenticadoService]},
  {path: "seguranca", loadChildren: () => import('./frameworks/seguranca/seguranca.module').then(m => m.SegurancaModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "autenticacao", component: TelaAutenticacaoComponent, canActivate: [GuardaUsuarioNaoAutenticadoService] },
  {path: "producao", loadChildren: () => import('./modulos/producao/producao.module').then(m => m.ProducaoModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "publico", loadChildren: () => import('./modulos/publico/publico.module').then(m => m.PublicoModule)},
  {path: "compliance", loadChildren: () => import('./modulos/compliance/compliance.module').then(m => m.ComplianceModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "visualizarUsuario/:idUsuario", outlet:"janelaLateralUsuario", component: JanelaUsuarioComponent},
  {path: "gestao-recebimento", loadChildren: './modulos/recebimento/recebimento.module#RecebimentoModule', canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoDashboardGuard]},
  {path: "cosseguro-alfa", loadChildren: () => import('./modulos/cosseguro-alfa/cosseguro.module').then(m => m.CosseguroModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoModuloService] },
  {path: "atuarial", loadChildren: () => import('./modulos/atuarial/atuarial.module').then(m => m.AtuarialModule), canActivate: [GuardaUsuarioAutenticadoService, GuardaAcessoModuloService] },
  {path: "mensagem", component: MensagemComponent, canActivate: [GuardaUsuarioAutenticadoService]}
];

/*

Para que o link com as pesquisas funcione corretamente é necessário utilizar o simbolo #
para que o browser entenda que o link é uma ancora para a própria página, não havendo necessidade de 
buscar o endereço no servidor.

Ver descrição abaixo:

https://stackoverflow.com/questions/52563837/angular-using-in-urls

PathLocationStrategy vs {useHash: true}

*/

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
